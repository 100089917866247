<template>
  <el-dialog title="会员服务协议" :visible.sync="visible" width="800px" :before-close="close" append-to-body>
    <div v-html="content"></div>
  </el-dialog>
</template>

<script>
export default {
  name: "serviceAgreement",
  data() {
    return {
      content: "",
      visible: false,
    }
  },
  methods: {
    open(e) {
      this.content = e;
      this.visible = true;
    },
    close() {
      this.visible = false;
    }
  }
}
</script>

<style scoped>

</style>