<template>
  <el-dialog title="订阅" :visible.sync="visible" width="600px" :before-close="close" :close-on-click-modal="false">
    <div class="subscribe-to" v-loading="loading">
      <div class="members-header">
        <div class="members-header-info">
          <div class="members-header-info-user">
            <div class="name">
              <div class="name-text" v-if="memberInfo.nick_name">{{ memberInfo.nick_name }}</div>
              <div class="name-text" v-else-if="memberInfo.real_name">{{ memberInfo.real_name }}</div>
              <div class="name-text" v-else>{{ memberInfo.mobile }}</div>
              <div class="member-tag vip-tag" v-if="memberInfo.vip_level">{{ memberInfo.user_level_name }}</div>
              <div class="member-tag ordinary-tag" v-else>普通会员</div>
            </div>
            <div class="tip" v-if="memberInfo.vip_level">
              <span>会员有效期至：{{ publishTimeFormat(memberInfo.vip_expire, 'YYYY-MM-DD') }}</span>
            </div>
            <div class="tip" v-else>
              <span>暂未开通VIP会员</span>
            </div>
          </div>
        </div>
        <div class="members-header-desc">
          <div class="privilege">
            {{ activeTab.user_level_name }}特权
          </div>
          <div class="tips">
            {{ activeTab.rights }}
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="tabClick" stretch>
        <el-tab-pane v-for="(tab, tabindex) in tabList" :label="tab.user_level_name" :name="'first'+tabindex" :key="tabindex">
          <div class="members-vip" v-if="activeTab.type == 1">
            <div class="price-list">
              <div v-for="(item, index) in activeTab.user_level_details" :class="{'price-list_item-cell': true, 'active': item.id == priceObj.id} " :key="index" @click="choosePrice(item)">
                <div class="month">{{item.name}}</div>
                <div class="price">
                  <span class="unit">￥</span>
                  <span v-if="item.first_time_price">{{item.first_time_price}}</span>
                  <span v-else-if="item.fact_price">{{item.fact_price}}</span>
                  <span v-else>{{item.price}}</span>
                </div>
                <div class="const-price" v-if="item.first_time_price || item.fact_price">
                  原价￥{{ item.price }}
                </div>
                <div class="vip-badge" v-if="item.most_buy_count">
                  最多人购买
                </div>
                <div class="vip-badge" v-else-if="item.first_time_price">
                  新用户首月
                </div>
              </div>
            </div>
<!--            <div class="desc" v-if="priceObj.price && (priceObj.first_time_price || priceObj.fact_price)">-->
<!--              到期将恢复￥{{priceObj.price}}-->
<!--            </div>-->
            <el-empty description="暂无数据" v-if="!activeTab.user_level_details || activeTab.user_level_details.length == 0"></el-empty>
          </div>
          <div class="members-institution" v-if="activeTab.type == 2">
            <div class="title">购买会员</div>
            <div class="tips">
              <div class="tips-one">
                {{ activeTab.prompt }}
              </div>
              <div class="tips-two">
                {{ configObj.CONNECT_MOBILE }}
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 学生资料，学生身份需要上传 -->
      <div class="members-upload" @click="openStudent" v-if="activeTab.examine == 1 && !activeTab.is_examine">
        <div class="title">
          <i class="el-icon-upload"></i>
          <span>上传资料</span>
        </div>
        <div class="desc">
          <span>上传身份证/学生证后进行购买</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="members-remark">
        <div class="accord">
          <span>开通前请阅读</span>
          <span class="text-link" @click="openProtocol">《会员服务协议》</span>
        </div>
        <div class="tip" v-if="activeTab.tips">
          {{ activeTab.tips }}
        </div>
      </div>
      <div class="opera-submit" v-if="activeTab.type == 1">
        <template v-if="activeTab.examine == 1 && !activeTab.is_examine">
          <div class="opera-submit_tips">
            请先上传资料,等待资料审核通过后可购买
          </div>
        </template>
        <template v-else>
          <div class="opera-submit_total">
            <span>合计：</span>
            <span class="price">￥{{ payPrice }}</span>
          </div>
          <el-button type="warning" @click="createOrder" size="medium" :disabled="activeTab.examine == 1 && !activeTab.is_examine" round>同意协议并支付￥{{ payPrice }}</el-button>
        </template>
      </div>
      <!-- 会员服务协议 -->
      <service-agreement ref="serviceAgreementRef"></service-agreement>
      <!-- 会员认证 -->
      <member-student ref="memberStudentRef"></member-student>
      <!-- 支付的二维码窗口 -->
      <payment-qrcode ref="paymentQrcodeRef" @success="close" @callback="close"></payment-qrcode>
    </div>
  </el-dialog>
</template>

<script>
import { createVipOrder,
  //orderDetail, createPayQrCode
} from "@/api";
import { userInfo, memberLevelList } from "@/api";

import ServiceAgreement from "./serviceAgreement";
import MemberStudent from "./memberStudent";
import PaymentQrcode from "./paymentQrcode";
export default {
  name: "SubscribeTo",
  components: {
    ServiceAgreement,
    MemberStudent,
    PaymentQrcode
  },
  data() {
    return {
      // 窗口是否显示
      visible: false,
      // 加载状态
      loading: false,
      // 当前选中-适配插件使用，无具体作用
      activeName: "first0",
      // 会员信息
      memberInfo: {},
      // 会员类型选择
      tabList: [],
      // 当前选中的信息
      activeTab: {},
      // 选中的价格信息
      priceObj: {},
      // 配置信息
      configObj: {
        CONNECT_MOBILE: "",
        MEMBERSHIP_SERVICE_AGREEMENT: ""
      }
    }
  },
  computed: {
    // 支付金额
    payPrice() {
      if (this.priceObj && this.priceObj.first_time_price) {
        return this.priceObj.first_time_price;
      } else if (this.priceObj && this.priceObj.fact_price) {
        return this.priceObj.fact_price;
      } else if (this.priceObj && this.priceObj.price) {
        return this.priceObj.price;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 打开窗口
    open(e) {
      this.visible = true;
      this.handleConfig(e);
      this.getMemberLevelList();
    },
    // 关闭窗口
    close() {
      this.visible = false;
    },
    // 处理配置信息
    handleConfig(arr) {
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (item.config_key == "CONNECT_MOBILE") {
            this.configObj.CONNECT_MOBILE = item.config_value;
          } else if (item.config_key == "MEMBERSHIP_SERVICE_AGREEMENT") {
            this.configObj.MEMBERSHIP_SERVICE_AGREEMENT = item.config_value;
          }
        }
      }
    },
    // 处理发表时间
    publishTimeFormat(date, formats) {
      if (date) {
        return this.$moment(date).format(formats);
      }
      return date ? date : "";
    },
    // 获取会员类型列表
    async getMemberLevelList() {
      this.loading = true;
      await this.getUserInfo();
      let res = await memberLevelList({ orderBy: "id", sortedBy: "asc" });
      if (res && res.code == 200) {
        this.tabList = res.data.list;
        this.activeTab = this.tabList && this.tabList.length > 0 ? this.tabList[0] : {};
        this.priceObj = this.activeTab && this.activeTab.user_level_details && this.activeTab.user_level_details.length > 0 ? this.activeTab.user_level_details[0] : {};
      }
      this.loading = false;
    },
    // 获取用户信息
    async getUserInfo() {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        let res = await userInfo();
        if (res && res.code == 200) {
          this.memberInfo = res.data.user_info;
        }
      }
    },
    // 选项卡选择事件
    tabClick(e) {
      this.activeTab = this.tabList[Number(e.index)];
      this.priceObj = this.activeTab && this.activeTab.user_level_details && this.activeTab.user_level_details.length > 0 ? this.activeTab.user_level_details[0] : {};
    },
    // 选择价格
    choosePrice(e) {
      this.priceObj = e;
    },

    // 打开上传学生资料
    openStudent() {
      this.$refs.memberStudentRef.open(this.memberInfo.mobile);
    },
    // 打开会员协议
    openProtocol() {
      this.$refs.serviceAgreementRef.open(this.configObj.MEMBERSHIP_SERVICE_AGREEMENT);
    },
    //下单
    async createOrder(){
      if (this.priceObj){
        let token = localStorage.getItem("cf40-token");
        if (token) {
          let res = await createVipOrder({user_level_detail_id:this.priceObj.id});
          if (res && res.code == 200) {
              this.$refs.paymentQrcodeRef.open(res.data.order.id);
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 0 20px 20px 20px;
  }
  .el-tabs__item.is-active, .el-tabs__item:hover {
    color: #876a52;
  }
  .el-tabs__active-bar {
    background-color: #a88465;
  }
}
.subscribe-to {
  .member-tag {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 10px;
    border-radius: 5px;
    margin-left: 10px;
  }
  .vip-tag {
    color: #b89677;
    background: linear-gradient(to right, #ffeede,#ffffff);
  }
  .ordinary-tag {
    color: #ffffff;
    background-color: #d9d9d9;
  }
  .members-header {
    padding: 15px;
    &-info {
      display: flex;
      align-items: center;
      &-user {
        .name {
          color: #a88465;
          font-size: 16px;
          display: flex;
          align-items: center;
        }
        .tip {
          padding-top: 4px;
          font-size: 14px;
          color: #af9173;
        }
      }
    }
    &-desc {
      color: #876a52 ;
      padding-top: 10px;
      .privilege {
        font-size: 15px;
        font-family: "HarmonyOS_Bold";
      }
      .tips {
        padding-top: 3px;
        font-size: 14px;
      }
    }
  }
  .members-vip {
    .price-list {
      display: flex;
      flex-wrap: wrap;
      &_item-cell {
        margin-top: 25px;
        width: calc(100% / 4 - 55px);
        min-width: 75px;
        position: relative;
        padding: 15px 15px;
        border-radius: 10px;
        background-color: #f3f3f3;
        border: 3px solid #f3f3f3;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 15px;
        cursor: pointer;
        z-index: 1;
        .month {
          font-size: 16px;
          color: #000000;
        }
        .price {
          color: #000000;
          font-size: 28px;
          font-family: "HarmonyOS_Bold";
          padding: 12px 0;
          display: flex;
          .unit {
            font-size: 13px;
            color: #7c7b7b;
            font-weight: 500;
            padding-top: 4px;
          }
        }
        .const-price {
          font-size: 14px;
          color: #8c8b8b;
        }
        &:last-child {
          margin-right: 0;
        }
        &.active {
          background-color: #fcf3eb;
          border: 3px solid #cca98b;
        }
        .vip-badge {
          position: absolute;
          top: -15px;
          left: -3px;
          color: #ffffff;
          font-size: 12px;
          padding: 5px 10px;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          border-bottom-right-radius: 8px;
          background-color: #cca98b;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 30;
        }
      }
    }
    .desc {
      padding-top: 15px;
      text-align: center;
      font-size: 14px;
      color: #a8a8a8;
    }
  }
  .members-institution {
    margin-top: 8px;
    padding: 15px;
    border-radius: 5px;
    background-color: #ffffff;
    .title {
      font-size: 17px;
      color: #000000;
      font-family: "HarmonyOS_Bold";
    }
    .tips {
      &-one {
        font-size: 15px;
        margin-top: 15px;
        color: #909399;
        text-align: center;
      }
      &-two {
        margin-top: 10px;
        text-align: center;
        color: #876a52;
        font-size: 20px;
      }
    }
  }
  .members-upload {
    margin-top: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ebeef5;
    .desc {
      flex: 1;
      text-align: right;
      cursor: pointer;
      &:hover {
        color: #cca98b;
      }
    }
  }
  .members-remark {
    .accord {
      margin-top: 15px;
      font-size: 14px;
      color: #000000;
      .text-link {
        color: #cca98b;
        cursor: pointer;
      }
    }
    .tip {
      padding-top: 8px;
      line-height: 20px;
      font-size: 14px;
      color: #777777;
    }
  }
  .opera-submit {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_tips {
      width: 100%;
      font-size: 14px;
      color: #876a52;
      font-family: "HarmonyOS_Bold";
      display: flex;
      justify-content: flex-end;
    }
    &_total {
      display: flex;
      align-items: center;
      .price {
        color: #dd7643;
        font-size: 18px;
        font-family: "HarmonyOS_Bold";
      }
    }
  }
}
</style>
