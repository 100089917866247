<template>
  <el-dialog title="微信扫码登录" :visible.sync="visible" width="400px" @close="close" :close-on-click-modal="false">
    <div class="login-applet" v-loading="loading">
      <el-image :src="qr_code" lazy style="width: 300px; height: 300px"></el-image>
    </div>
  </el-dialog>
</template>

<script>
import { appletQR, testLogin } from "@/api";
export default {
  name: "LoginApplet",
  data() {
    return {
      loading: false,
      // 二位码图片
      qr_code: "",
      // 验证token
      validateToken: "",
      // 窗口是否显示
      visible: false,
      // 二维码定时器
      qrTimer: null,
      // 检测登录定时器
      testTimer: null
    }
  },
  methods: {
    // 打开窗口
    open() {
      this.refreshQr();
      this.visible = true;
    },
    // 关闭窗口
    close () {
      clearInterval(this.testTimer);
      clearInterval(this.qrTimer);
      this.visible = false;
    },
    // 二维码刷新
    refreshQr() {
      let _This = this;
      this.getQrCode();
      this.qrTimer = setInterval(function() {
        _This.getQrCode();
      }, 300000);
    },
    // 获取二维码
    async getQrCode() {
      let _This = this;
      this.loading = true;
      let res = await appletQR();
      if (res && res.code == 200) {
        this.qr_code = `data:image/png;base64,${res.data.data}`;
        this.validateToken = res.data.stoken;
        this.testTimer = setInterval(function() {
          _This.detectLogin();
        }, 1000);
      }
      this.loading = false;
    },
    // 检测登录
    async detectLogin() {
      let res = await testLogin({ stoken: this.validateToken });
      if (res && res.code == 200) {
        if (res.data && res.data.token) {
          localStorage.setItem("cf40-token", res.data.token);
          this.$emit("success");
          this.close();
        }
      } else {
        clearInterval(this.testTimer);
        clearInterval(this.qrTimer);
        this.refreshQr();
      }
    }
  }
}
</script>

<style scoped>
.login-applet {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>