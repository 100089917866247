<template>
  <div class="index">
    <el-backtop :bottom="60"></el-backtop>
    <div class="header-menu">
      <div class="header-menu_container">
        <div class="header-menu_logo">
          <a href="/">
            <img :src="configObj.PCLOGO" alt="四十人研究" v-if="configObj.PCLOGO" />
            <span style="font-size: 20px; font-family: 'HarmonyOS_Bold';" v-else>北京四十人信息科技有限公司</span>
          </a>
        </div>
        <div class="header-menu_filter">
          <div class="lan-switch" @click="switchToEn">
            English
          </div>
          <div class="header-menu_wrap">
            <div class="header-menu_wrap_search">
              <el-input placeholder="请输入关键词" v-model="searchForm.title" @keyup.enter.native="searchData" @clear="searchData" clearable>
                <el-button slot="append" icon="el-icon-search" @click="searchData"></el-button>
              </el-input>
            </div>
            <div class="header-menu_wrap_opera">
              <el-button type="text" size="medium" @click="jumpSub">订阅</el-button>
              <el-divider direction="vertical"></el-divider>
              <div class="avatar" v-if="memberInfo && memberInfo.id">
                <el-avatar icon="el-icon-user-solid"></el-avatar>
                <span class="name">{{ memberInfo.nick_name || memberInfo.real_name }}</span>
                <el-button type="text" @click="logout">退出</el-button>
              </div>
              <el-button type="text" size="medium" @click="login" v-else>登录</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-main">
      <el-row :gutter="30">
        <el-col :span="16" v-loading="loading">
          <el-card class="card-wrap" v-for="(item, index) in dataList" :key="index">
            <div class="card-wrap_content" @click="jumpToDetail(item)">
              <div class="img_icon" v-if="reportImage(item)">
                <el-image :src="reportImage(item)" style="max-width: 100%;">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div class="right-content">
                <div class="right-content_title">
                  <span>{{ item.product_name }}</span>
                  <img :src="item.product_class.small_icon.img" style="max-width: 38px; margin-left: 10px" v-if="item.product_class && item.product_class.small_icon && item.product_class.small_icon.img" />
                </div>
                <div :class="{ 'right-content_desc': true, 'spacing': !reportImage(item) }">
                 {{ removeHtmlTags(item.content) }}
                </div>
                <div class="right-content_tag" v-if="item.product_label && item.product_label.length > 0">
                  <el-tag type="warning" v-for="(jtem, jndex) in item.product_label" :key="jndex">{{ jtem.label_name }}</el-tag>
                </div>
                <div :class="{ 'right-content_foot': true, 'toTop': !reportImage(item) }">
                  <span>{{ item.author }}</span>
                  <span>{{ publishTimeFormat(item.publish_time, 'YYYY-MM-DD') }}</span>
                </div>
              </div>
            </div>
          </el-card>
          <el-card class="card-wrap" v-if="!dataList || dataList.length == 0">
            <el-empty description="暂无内容"></el-empty>
          </el-card>
          <div class="pages">
            <el-pagination
                background
                :current-page="currentPage"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="dataSizeChange"
                @current-change="dataCurrentChange"
                :total="total">
            </el-pagination>
          </div>
        </el-col>
        <el-col :span="8">
          <el-card class="card-right-class">
<!--            <div class="class-title">-->
<!--              <span>分类</span>-->
<!--            </div>-->
            <div class="class-content">
              <div :class="{ 'class-content_item': true, 'active': item.id == selectedClass }" v-for="(item, index) in reportClass" :key="index" @click="chooseClass(item.id)">
                <div class="class-content_item_icon">
                  <el-image :src="item.icon && item.icon.img ? item.icon.img : ''">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <div class="class-content_item_title">{{ item.class_name }}</div>
              </div>
            </div>
          </el-card>
          <el-card class="card-right-recommend">
            <div class="recommend-title">
              <span>推荐阅读</span>
            </div>
            <div class="recommend-content">
              <div class="recommend-content_item" v-for="(item, index) in recommendList" :key="index" @click="jumpToDetail(item)">
                <div class="recommend-content_item_desc">
                  <div class="recommend-content_item_desc_title toBottom">
                    <span>{{ item.product_name }}</span>
                    <img :src="item.product_class.small_icon.img" style="max-width: 38px; margin-left: 10px" v-if="item.product_class && item.product_class.small_icon && item.product_class.small_icon.img" />
                  </div>
                  <div class="recommend-content_item_desc_foot">
                    <span>{{ item.author }}</span>
                    <span>{{ publishTimeFormat(item.publish_time, 'YYYY-MM-DD') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="index-footer">
      <div class="index-footer_container">
        <div class="row footer-contact">
          <div class="footer-contact-right">
            <div class="item-cell">
              <span>Tel/联系电话：</span>
              <span>{{ configObj.CONNECT_MOBILE }}</span>
            </div>
            <div class="item-cell">
              <span>Address/地址：</span>
              <span>{{ configObj.ADDRESS }}</span>
            </div>
            <div class="item-cell">
              <span>Mail/邮箱：</span>
              <span>{{ configObj.EMAIL }}</span>
            </div>
          </div>
        </div>
        <div class="copy-right">
          <span v-if="configObj.BEIAN">© 2023 <a href="http://www.beian.miit.gov.cn/" target="_blank">{{ configObj.BEIAN }}</a></span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您访问北京四十人信息科技有限公司</span>
        </div>
      </div>
    </div>

    <!-- 登录窗口 -->
    <login-applet ref="loginAppletRef" @success="refreshRequest"></login-applet>
    <!-- 支付窗口 -->
    <pay-wechat ref="payWechatRef" @error="refreshRequest" @success="paySuccess"></pay-wechat>
    <!-- 订阅窗口 -->
    <subscribe-to ref="subscribeToRef" @success="refreshRequest"></subscribe-to>
  </div>
</template>

<script>
import LoginApplet from "@/components/loginApplet";
import PayWechat from "@/components/payWechat";
import SubscribeTo from "@/components/subscribeTo";

import { classifyList, reportList, initConfig, userInfo, reportDetail, reportPdf } from "@/api";
export default {
  name: "Index",
  components: {
    PayWechat,
    LoginApplet,
    SubscribeTo
  },
  data() {
    return {
      searchForm: {
        title: ""
      },
      // 配置信息
      configObj: {
        CONNECT_MOBILE: "",
        ADDRESS: "",
        EMAIL: "",
        BEIAN: "",
        PCLOGO: ""
      },
      // 所有的配置信息
      allConfig: [],
      // 用户信息
      memberInfo: {},
      // 分类列表
      reportClass: [],
      // 选中的分类
      selectedClass: "",
      // 推荐阅读列表
      recommendList: [],
      // 文章ID，是否未登录直接点击了文章
      isLoginReportId: null,
      // 数据加载状态
      loading: false,
      // 数据列表
      dataList: [],
      // 当前页
      currentPage: 1,
      // 每页显示的条数
      pageSize: 10,
      // 总条数
      total: 0
    }
  },
  created() {
    this.refreshRequest();
    this.getInitCofing();
    this.getReportClass();
  },
  methods: {
    // 刷新请求
    refreshRequest() {
      this.getRecommendList();
      this.getReportList();
      this.getUserInfo();
      if (this.isLoginReportId) {
        this.getReportDetail(this.isLoginReportId);
      }
    },
    // 获取分类列表
    async getReportClass() {
      let param = {
        pageSize: 50,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "desc",
        search: "type:1|type:1",
        searchFields: "type:=|type:="
      };
      this.reportClass = [];
      let res = await classifyList(param);
      if (res && res.code == 200) {
        if (res.data.list && res.data.list.length > 0) {
          for (let i = 0; i < res.data.list.length; i++) {
            let item = res.data.list[i];
            if (item.id == 16 || item.id == 17 || item.id == 18) {
              this.reportClass.push(item);
            }
          }
        }
      }
    },
    // 获取推荐阅读研究报告列表
    async getRecommendList() {
      let param = {
        pageSize: 10,
        currentPage: 1,
        search: "product_type:1|product_class_id:16,17,18|recommend:0|pdf_url_id:0",
        searchFields: "product_type:=|product_class_id:in|recommend:>|pdf_url_id:>",
        orderBy: "publish_time",
        sortedBy: "desc"
      };
      let res = await reportList(param);
      if (res.code == 200) {
        this.recommendList = res.data.list;
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        let res = await userInfo();
        if (res && res.code == 200) {
          this.memberInfo = res.data.user_info;
        }
      }
    },
    // 获取研究报告数据列表
    async getReportList() {
      this.loading = true;
      let param = {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        search: "product_type:1|pdf_url_id:0|",
        searchFields: "product_type:=|pdf_url_id:>|",
        orderBy: "publish_time",
        sortedBy: "desc"
      };
      if (this.searchForm.title && this.searchForm.title.trim()) {
        param.search = `${param.search}product_name:${this.searchForm.title}|`;
        param.searchFields = `${param.searchFields}product_name:like|`;
      }
      if (this.selectedClass) {
        param.search = `${param.search}product_class_id:${this.selectedClass}|`;
        param.searchFields = `${param.searchFields}product_class_id:=|`;
      } else {
        param.search = `${param.search}product_class_id:16,17,18|`;
        param.searchFields = `${param.searchFields}product_class_id:in|`;
      }
      let res = await reportList(param);
      if (res.code == 200) {
        this.dataList = res.data.list;
        this.total = res.data.total;
      }
      this.loading = false;
    },
    // 设置每页显示多少条
    dataSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getReportList();
    },
    // 设置当前页
    dataCurrentChange(val) {
      this.currentPage = val;
      this.getReportList();
    },

    // 选择ID
    chooseClass(class_id) {
      if (class_id == this.selectedClass) {
        this.selectedClass = "";
      } else {
        this.selectedClass = class_id;
      }
      this.getReportList();
    },
    // 处理发表时间
    publishTimeFormat(date, formats) {
      if (date) {
        return this.$moment(date).format(formats);
      }
      return date ? date : "";
    },
    // 处理显示的图片
    reportImage(row) {
      if (row && row.img && row.img.length > 0) {
        return row.img[0]['img'];
      }
      return false;
    },
    // 去除HTML标签
    removeHtmlTags(value) {
      return value ? value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, '') : "暂无简介";
    },
    // 搜索
    searchData() {
      this.currentPage = 1;
      this.getReportList();
    },
    // 获取配置信息
    async getInitCofing() {
      let _This = this;
      let res = await initConfig();
      if (res && res.code == 200) {
        this.allConfig = res.data;
        if (res.data.length > 0) {
          let temp = {};
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == "CONNECT_MOBILE") {
              temp['CONNECT_MOBILE'] = item.config_value;
            } else if (item.config_key == "ADDRESS") {
              temp['ADDRESS'] = item.config_value;
            } else if (item.config_key == "EMAIL") {
              temp['EMAIL'] = item.config_value;
            } else if (item.config_key == "BEIAN") {
              temp['BEIAN'] = item.config_value;
            } else if (item.config_key == "PCLOGO") {
              temp['PCLOGO'] = item.config_value;
            }
          }
          _This.configObj = temp;
        }
      }
    },
    // 获取报告详情
    async getReportDetail(reportId) {
      let res = await reportDetail({ id: reportId });
      if (res && res.code == 200) {
        if (res.data && res.data.is_buy) {
          let reportRes = await reportPdf({ id: reportId });
          const eleLink = document.createElement("a"); // 新建A标签
          eleLink.href =  window.URL.createObjectURL(reportRes); // 下载的路径
          eleLink.style.display = "none";
          eleLink.target = "_blank";
          document.body.appendChild(eleLink);
          eleLink.click(); // 触发点击事件
          document.body.removeChild(eleLink);
        } else {
          this.$refs.payWechatRef.open(res.data.id);
        }
      }
    },
    // 跳转到详情
    jumpToDetail(row) {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        this.getReportDetail(row.id);
      } else {
        this.isLoginReportId = row.id;
        this.$message({ message: "请先登录后再进行查看，3s后将自动打开登录窗口", type: "warning" });
        setTimeout(this.login, 3000);
      }
    },
    // 支付成功
    paySuccess(reportId) {
      this.getReportDetail(reportId);
    },

    // 打开订阅窗口
    jumpSub() {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        this.$refs.subscribeToRef.open(this.allConfig);
      } else {
        this.$message({ message: "请先登录后再进行订阅，3s后将自动打开登录窗口", type: "warning" });
        setTimeout(this.login, 3000);
      }
    },
    // 登录
    login() {
      this.$refs.loginAppletRef.open();
    },
    // 退出登录
    logout() {
      localStorage.clear();
      this.memberInfo = {};
      this.refreshRequest();
    },
    // 切换到英文版
    switchToEn() {
      this.$router.push({ path: "/en" });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input.is-active .el-input__inner, .el-input__inner:focus {
    border-color: #dd7643 !important;
  }
  .el-backtop {
    color: #dd7643;
  }
}
.index {
  height:100%;
  overflow: auto;
  &-main {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    .card-wrap {
      margin-bottom: 20px;
      cursor: pointer;
      &_content {
        display: flex;
        .img_icon {
          min-width: 240px;
          height: 180px;
          padding-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .right-content {
          padding: 5px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &_title {
            font-family: "HarmonyOS_Bold";
            font-size: 20px;
            color: #303133;
          }
          &_desc {
            font-size: 14px;
            color: #909399;
            line-height: 30px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: justify;
            &.spacing {
              margin: 10px 0;
            }
          }
          &_tag {
            .el-tag {
              margin-right: 10px;
            }
          }
          &_foot {
            font-size: 14px;
            color: #909399;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.toTop {
              margin-top: 10px;
            }
          }
        }
      }
      &:hover {
        .right-content_title {
          color: #dd7643;
        }
      }
    }
    .card-right-class {
      margin-bottom: 20px;
      .class-title {
        font-size: 16px;
        color: #303133;
        padding-bottom: 10px;
        border-bottom: 1px solid #E4E7ED;
      }
      .class-content {
        display: flex;
        flex-wrap: wrap;
        &_item {
          width: calc(100% / 3);
          margin: 10px 0;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          &_icon {
            width: 66px;
            height: 66px;
            padding: 5px;
            border-radius: 50%;
            background-color: #f8f2f2;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &_title {
            padding-top: 10px;
            font-family: "HarmonyOS_Bold";
            font-size: 16px;
          }
          &.active {
            .class-content_item_title {
              color: #dd7643;
            }
          }
        }
      }
    }
    .card-right-recommend {
      .recommend-title {
        font-size: 18px;
        color: #303133;
        padding-bottom: 10px;
        border-bottom: 1px solid #E4E7ED;
        font-family: "HarmonyOS_Bold";
      }
      .recommend-content {
        &_item {
          margin-top: 10px;
          cursor: pointer;
          display: flex;
          padding-bottom: 6px;
          border-bottom: 1px solid #E4E7ED;
          &:last-child {
            border-bottom: none;
          }
          &_icon {
            width: 35%;
            margin-right: 10px;
          }
          &_desc {
            flex: 1;
            padding: 5px 0;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &_title {
              font-size: 16px;
              color: #303133;
              &.toBottom {
                margin-bottom: 10px;
                line-height: 28px;
              }
            }
            &_foot {
              color: #606266;
              font-size: 14px;
              display: flex;
              justify-content: space-between;
            }
          }
          &:hover {
            background-color: #f8f2f2;
            .recommend-content_item_desc_title {
              color: #dd7643;
            }
          }
        }
      }
    }
  }
  &-footer {
    margin-top: 30px;
    padding-top: 30px;
    background-image: url("~@/assets/footer-bg.jpg");
    background-repeat: no-repeat;
    background-position: 100%;
    &_container {
      max-width: 1200px;
      margin: 0 auto;
      .footer-contact {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }
      .footer-contact-right {
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-left: 10px;
        font-size: 14px;
        .item-cell + .item-cell {
          margin-left: 10px;
        }
      }
      .copy-right {
        font-size: 14px;
        color: #ffffff;
        padding: 10px 0;
        text-align: center;
        a {
          color: #ffffff;
          text-decoration: none;
          &:hover {
            color: #dd7643;
          }
        }
      }
    }
  }
}
</style>
