<template>
  <el-dialog title="扫码支付" :visible.sync="visible" width="400px" :before-close="close" :close-on-click-modal="false">
    <div class="pay-wechat" v-loading="loading">
      <vue-qr
          :logo-src="require('@/assets/cf40.png')"
          :logo-scale="50"
          :text="qr_code"
          :size="350"/>
      <div style="text-align: center" v-if="price">
        <div>本文为付费内容，</div>
        <div>请扫码支付({{ price }}元/篇)或成为订阅会员后登录查看</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { createOrder, createPayQrCode, orderDetail } from "@/api";
import VueQr from 'vue-qr'
export default {
  name: "PayWechat",
  components: {
    VueQr
  },
  data() {
    return {
      visible: false,
      loading: false,
      // 报告ID
      reportId: null,
      // 订单ID
      orderId: null,
      // 订单是否支付完成轮询定时器
      orderTimer: null,
      // 支付金额
      price: "",
      // 支付参数
      qr_code: "",
    }
  },
  methods: {
    // 打开窗口
    open(productId) {
      if (productId) {
        this.reportId = productId;
        this.buildOrder(productId);
        this.visible = true;
      } else {
        this.$message.error("您选择的文件不存在，请刷新后重试");
        this.$emit("error");
      }
    },
    // 关闭窗口
    close () {
      clearInterval(this.orderTimer);
      this.visible = false;
    },
    // 创建订单
    async buildOrder(productId) {
      this.loading = true;
      let res = await createOrder({ product_id: productId });
      if (res && res.code == 200) {
        if (res.data && res.data.order && res.data.order.id) {
          this.price = res.data.order.pay_amount;
          this.orderId = res.data.order.id;
          this.buildPayQrCode(res.data.order.id);
        }
      }
    },
    // 创建支付
    async buildPayQrCode(orderId) {
      let _This = this;
      let res = await createPayQrCode({ order_id: orderId });
      if (res && res.code == 200) {
        this.qr_code = res.data.code_url;
        this.orderTimer = setInterval(function() {
          _This.payments();
        }, 1000);
      }
      this.loading = false;
    },
    // 验证是否支付完成
    async payments() {
      let res = await orderDetail({ id: this.orderId });
      if (res && res.code == 200) {
        if (res.data && res.data.status == 2) {
          this.close();
          this.$message.success("支付成功");
          this.$emit("success", this.reportId);
        }
      }
    }
  }
}
</script>


<style scoped>
.pay-wechat {
  text-align: center;
}
</style>