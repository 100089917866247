import request from '@/utils/request'


/**
 * 获取七牛token
 * @returns
 */
export async function getQiNiuToken (param) {
    return request({
        url: '/pc/api/main/uploadToken',
        method: 'POST',
        data: param
    });
}
/**
 * 资源库-添加文件进素材库
 * @returns
 */
export async function addFiles (param) {
    return request({
        url: '/pc/api/main/addImg',
        method: 'post',
        data: param
    });
}