<template>
  <el-dialog title="支付" :visible.sync="visible" width="400px" :close-on-click-modal="false" append-to-body>
    <div v-loading="loading">
      <vue-qr
          :logo-src="require('@/assets/cf40.png')"
          :logo-scale="50"
          :text="qr_code"
          :size="350"/>
    </div>
  </el-dialog>
</template>

<script>
import VueQr from 'vue-qr'
import {createPayQrCode,orderDetail} from "@/api";
//import { userInfo, memberLevelList } from "@/api";
export default {
  name: "paymentQrcode",
  components: {
    VueQr
  },
  data() {
    return {
      visible: false,
      // 加载状态
      loading: false,
      // 二位码图片
      qr_code: "",
    }
  },
  methods: {
    // 打开窗口
    open(id) {
      this.visible = true;
      this.pay(id)
      this.timer(id,0);
    },
    timer(id,times){
      setTimeout(async ()=>{
        times++;
        if(times > 100){
          this.close();
          return;
        }
        let token = localStorage.getItem("cf40-token");
        if (token) {
          let res = await orderDetail({id:id})
          if (res && res.code == 200) {
            if (res.data.status == 2){
              this.$message.success("支付成功");
              this.close();
              setTimeout(()=>{location.reload();},3000)
            }else if(res.data.status == 1){
              this.timer(id,times);
            }
          }else{
            this.$message.error(res.msg);
          }
        }
      },1000)
    },
    async pay(id){
      this.loading = true;
      let token = localStorage.getItem("cf40-token");
      if (token) {
        let res = await createPayQrCode({order_id:id});
        if (res && res.code == 200) {
          this.qr_code = res.data.code_url;
        }
      }
      this.loading = false;
    },
    // 关闭窗口
    close() {
      this.visible = false;
    }
  }
}
</script>

<style scoped>
.payment-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
